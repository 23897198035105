/* eslint-disable react/no-danger */
import React from 'react';
import { Link, graphql } from 'gatsby';
import PageLayout from '@containers/PageLayout';
import Section from '@containers/Section';
import ReadLink from '@components/ReadLink/ReadLink';
import PropTypes from 'prop-types';
import kebabCase from 'lodash/kebabCase';
import readingTime from 'reading-time';
import { SUBSTACK_IFRAME } from '@constants';
import IFrame from '@src/components/iframe';
import {
  ImageContainer,
  TagContainer,
  Tag,
  PostHeaderRow,
  PostDate,
  ArticleLink,
  ArticleFooterList,
  Body,
} from './tool.styled';

export const query = graphql`
  query($slug: String!) {
    toolsJson(tools: { elemMatch: { slug: { eq: $slug } } }) {
      tools {
        title
        tags
        slug
        link
        image
        description
      }
    }
  }
`;

const ToolTemplate = ({ data: { toolsJson }, pageContext, location }) => {
  const { tools } = toolsJson;
  const tool = tools?.find((too) => too.slug === pageContext.slug);
  const { title, tags, description, image, link } = tool;
  const { previous, next } = pageContext;

  return (
    <PageLayout title={title} description={description}>
      <div className="bg-white">
        <div className="mx-auto px-4 max-w-screen-lg sm:px-6 lg:px-8">
          <div>
            <ReadLink to="/tools" text="Back to all tools" />
            <div className="space-y-5 sm:space-y-4 md:max-w-xl lg:max-w-3xl xl:max-w-none">
              <h1>{title}</h1>
              <PostHeaderRow>
                {/* <PostDate>
                  {date} — {stats.text}
                </PostDate> */}
                <TagContainer>
                  {tags?.map((tag) => (
                    <Tag key={tag}>{tag}</Tag>
                  ))}
                </TagContainer>
              </PostHeaderRow>
            </div>
            <div>
              {/* <div className="container mx-auto flex flex-col px-5 py-12 justify-center items-center">
                {image ? (
                  <img src={image} alt={title} className="w-64 rounded-lg" />
                ) : null}
              </div> */}
              <div>{description}</div>
              {/* <Body
                dangerouslySetInnerHTML={{
                  __html: body.childMarkdownRemark.html,
                }}
              /> */}
              <IFrame iframe={SUBSTACK_IFRAME} />

              <nav>
                <ArticleFooterList>
                  <li>
                    {previous && (
                      <ArticleLink to={`/tools/${previous.slug}`} rel="prev">
                        ← {previous.title}
                      </ArticleLink>
                    )}
                  </li>
                  <li>
                    {next && (
                      <ArticleLink to={`/tools/${next.slug}`} rel="next">
                        {next.title} →
                      </ArticleLink>
                    )}
                  </li>
                </ArticleFooterList>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </PageLayout>
  );
};

ToolTemplate.propTypes = {
  data: PropTypes.object,
  pageContext: PropTypes.object,
  location: PropTypes.object,
};

export default ToolTemplate;
