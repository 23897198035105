import { Link } from 'gatsby';
import Image from 'gatsby-image';
import styled from 'styled-components';
import { widthFrom, widthUntil } from '@theme/theme';

const ImageContainer = styled(Image)``;
const TagContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 6px 0;
  ${widthUntil.MOBILE`
      margin: 0;
  `}
`;

const Tag = styled.span.attrs((props) => ({
  className:
    'leading-5 text-sm font-medium inline-flex items-center px-3 py-0.5 rounded-full bg-teal text-white',
}))`
  text-transform: Capitalize;
  margin-right: 6px;
`;
const PostHeaderRow = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  margin-top: 6px;
  ${widthUntil.MOBILE`
        flex-direction: column;
  `}
`;

const PostDate = styled.span.attrs((props) => ({
  className: 'text-base leading-5 text-gray-500',
}))``;

const Body = styled.div.attrs((props) => ({
  className: 'text-xl text-gray-500 leading-8',
}))`
  h1, h2, h3, h4, h5, h6 {
    margin-top: 6px;
  }
  a {
    color: ${({ theme }) => theme.colors.teal};
    &:hover {
    color: ${({ theme }) => theme.colors.darkBlue};
      box-shadow: none;
    }
  }
  // a {
  //   border-bottom: 1px solid ${({ theme }) => theme.colors.accentYellow};
  //   box-shadow: inset 0 -1px 0 0 ${({ theme }) => theme.colors.accentYellow};
  //   &:hover {
  //     border-bottom: 1px solid ${({ theme }) => theme.colors.accentYellow};
  //     box-shadow: inset 0 -30px 0 0 ${({ theme }) =>
    theme.colors.accentYellow};
  //   }
  // }
`;

const ArticleFooterList = styled.ul.attrs((props) => ({
  className: '',
}))`
  display: flex;
  flex: wrap;
  justify-content: space-between;
  list-style: none;
  padding: 0;
`;

const ArticleLink = styled(Link).attrs((props) => ({
  className:
    'text-xl text-gray-500 leading-8 font-medium text-darkBlue hover:text-darkBlue mt-4',
}))`
  width: fit-content;
  &:hover {
    box-shadow: inset 0 -30px 0 0 ${({ theme }) => theme.colors.accentYellow};
  }
`;

export {
  ImageContainer,
  TagContainer,
  Tag,
  PostHeaderRow,
  PostDate,
  ArticleLink,
  ArticleFooterList,
  Body,
};
